<template lang="pug">
div(class="z-editor"  ref="refContent")
    div(v-if="props.menu && editor && showMenu" class="z-editor__menu")
        slot(name="menu-left")
        slot(name="menu-center")
            z-btn(@click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }" icon="format-bold")
            z-btn(@click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }" icon="format-italic")
            z-btn(@click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }" icon="format-underline")
            z-btn(@click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }" icon="format-strikethrough")
            z-btn(v-if="props.bulletList" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" icon="format-list-bulleted")
        slot(name="menu-right")
    editor-content(:editor="editor" :class="{'z-editor__content':true, 'z-editor-blank':isBlank}" :data-placeholder="props.placeholder" spellcheck="true")
</template>

<script setup>
import { defineProps, defineEmits, watch, onBeforeUnmount, ref, nextTick } from 'vue'
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import { Extension } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import History from '@tiptap/extension-history'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import {Slice, Fragment, Node} from 'prosemirror-model'
import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent);

const props = defineProps({
    modelValue: {default: ''},
    placeholder: {type: String, default: ''},
    autoFocus: {type: Boolean, default: false},
    rich: {type: Boolean, default: false},
    bulletList: {type: Boolean, default: false},
    preventEnter: {type: Boolean, default: true},
    menu: {type: Boolean, default: false},
    fixMenu: {type: Boolean, default: false},
})

const emit = defineEmits(['update:modelValue', 'ready', 'change', 'focus', 'blur'])
const showMenu = ref(props.fixMenu)

function getIsBlank(text){
    return (!text || text==='<p></p>')
}

const EnterFix = Extension.create({
    name: 'enter',
    addKeyboardShortcuts () {
        let result = {
            'Shift-Enter': () => {
                this.editor.commands.newlineInCode()
                this.editor.commands.createParagraphNear()
                this.editor.commands.liftEmptyBlock()
                this.editor.commands.splitBlock()
            },
            'Mod-Enter': () =>  true ,
        }
        if (props.preventEnter){
            result['Enter'] = () => true
        }
        return result
    },
})

const isBlank = ref(getIsBlank(props.modelValue))
const refContent = ref(null)

const extensionsList = [
    Document,
    Paragraph,
    Text,
    History.configure({ newGroupDelay: 100 }),
    EnterFix
]

if (props.rich) {
    extensionsList.push(Bold)
    extensionsList.push(Italic)
    extensionsList.push(Strike)
    extensionsList.push(Underline)
}

if (props.bulletList) {
    extensionsList.push(BulletList)
    extensionsList.push(ListItem)
}

const editor = useEditor({
    content: props.modelValue,
    extensions: extensionsList,
    parseOptions: {
        preserveWhitespace: true,
    },
    editorProps: {
        clipboardTextParser: (text, context, plain) => {
            const blocks = text.replace().split(/(?:\r\n?|\n)/)
            const nodes = []

            blocks.forEach(line => {
                let nodeJson = { type: 'paragraph' }
                if (line.length > 0) {
                    nodeJson.content = [{
                        type: 'text',
                        text: line
                    }]
                }
                let node = Node.fromJSON(context.doc.type.schema, nodeJson)
                nodes.push(node)
            })

            const fragment = Fragment.fromArray(nodes)
            return Slice.maxOpen(fragment)
        },
        autoFocus: props.autoFocus
    },
    onUpdate: () => {
        let editorValue =  editor.value.getHTML()
        // let editorValue =  editor.value.getHTML()
        emit('update:modelValue', editorValue)
        emit('change', editorValue)
        isBlank.value = getIsBlank(editorValue)
    },
    onSelectionUpdate: ()=>{
        if (props.menu && !props.fixMenu){
            showMenu.value = (editor.value.state.selection.from!==editor.value.state.selection.to)
        }
    },
    onBlur: event => emit('blur', event),
    onFocus: event => emit('focus', event)
})

watch(
    () => props.modelValue,
    (value) => {
        const editorValue = editor.value.getHTML()

        const isSame = ( editorValue === value )
        if (isSame) {
            return
        }
        editor.value.commands.setContent(props.modelValue, false)
        isBlank.value = getIsBlank(props.modelValue)
        // emit('change', 'editorValue')
    }
)


onBeforeUnmount(() => {
    editor.value.destroy()
})

function preventBlur(event, forced=false) {
    const getClasses = ()=>{
        let classes = ''
        let target = event.target
        while (target){
            classes = classes + ' ' + target.className
            target = target.parentNode
        }
        return classes
    }

    // console.log(getClasses().includes('ProseMirror'))

    if ((md.phone() || md.tablet()) && event && !getClasses().includes('ProseMirror')){ //(!refContent.value.contains(event.target))
        event.preventDefault();
        // console.log(getClasses())
    }

    // console.log(event)
    // if (forced || editor.value.isFocused){
    //     if(forced || !refContent.value.contains(event.target)){ //event.target.tagName!=='P'
    //         event.preventDefault();
    //         // console.log('!!!!!')
    //     }
    // }
}

function reset() {
    editor.value.destroy()
}

nextTick((() => {
    if (props.autoFocus){
        editor.value.commands.focus()
    }
    emit('ready', { editor: editor.value, preventBlur, reset })
}))

// console.log('create!')

</script>


<style scoped lang="css">
.z-editor-blank:before{
    content: attr(data-placeholder) !important;
    float: left;
    color: var(--editor-placeholder-color);
    pointer-events: none;
    height: 0;
    font-size: var(--font-size);
    white-space: nowrap;
}

.z-editor{
    overflow-y: auto;
    /*height: calc(100%);*/
}

.z-editor__content{
    /*background: red;*/
    height: 100%;
    white-space: break-spaces;
}

.z-editor__menu{
    display: flex;
    /* background: var(--primary-color); */
    transform: translateY(calc(-2.5rem - var(--indent-size)));
    position: absolute;
    width: calc(100% - var(--indent-size));
    justify-content: center;
    z-index: 2;
}

.z-editor__menu ::v-deep(.z-btn){
    max-width: 2.5rem;
    max-height: 2.5rem;
}

.z-editor__menu ::v-deep(.is-active){
    color: var(--regular-text-color);
}
</style>
